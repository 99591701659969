import { Component } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NgClass, NgIf, NgSwitch } from '@angular/common';

import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { LineBreaksPipe } from '../../pipes/line-breaks/line-breaks.pipe';

@Component({
    standalone: true,
    imports: [NgIf, NgClass, NgSwitch, FormsModule, LineBreaksPipe],
    selector: 'qwyk-simple-modal-portal',
    templateUrl: './simple-modal-portal.component.html',
    styleUrls: ['./simple-modal-portal.component.scss'],
})
export class SimpleModalPortal {
    public header!: string;
    public message!: string;
    public dismissAction = 'OK';
    public confirmAction: string | null = null;
    public confirmStyle = 'btn-primary';
    public dismissStyle = null;
    public showInput = false;
    public inputLabel = null;
    public inputPlaceholder = null;
    public inputType: string | null = null;
    public inputValue = null;
    public inputRequired = false;
    public inputHint = null;

    constructor(private modal: NgbActiveModal) {
    }

    public confirm() {
        if (this.showInput) {
            this.modal.close(this.inputValue);
        } else {
            this.modal.close();
        }
    }

    public dismiss() {
        this.modal.dismiss();
    }
}
