import {
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
} from '@angular/core';
import {
    FormBuilder,
    FormControl,
    FormGroup,
    Validators,
} from '@angular/forms';

import { Subscription } from 'rxjs';

import { Portals } from '@qwyk/models';

import { AuthenticationFacade } from '../../state/authentication.facade';
import { RequestedNavigation } from '../login-modal/login-modal.component';
import { ResendVerificationEmailServiceService } from '../../services/resend-verification-email-service.service';

@Component({
    selector: 'qwyk-login-form',
    templateUrl: './login-form.component.html',
    styleUrls: ['./login-form.component.scss'],
})
export class LoginFormComponent implements OnInit, OnDestroy {
    @Output() navigate: EventEmitter<RequestedNavigation> = new EventEmitter(
        null
    );
    @Input() design: Portals.SiteDesign;
    @Input() disablePasswordReset = false;
    @Output() completed: EventEmitter<void> = new EventEmitter();
    @Input() authenticationType: 'email' | 'username';
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    @Input() data: any;
    @Input() isMagayaNetwork: boolean;
    @Input() siteConfiguration: Portals.SiteConfig;
    loginForm: FormGroup;
    error$ = this.authentication.error$;
    authenticating$ = this.authentication.authenticating$;

    private authenticatedSubscription: Subscription;
    private authenticatingSubscription: Subscription;
    resendingConfirmation = false;
    resendConfirmationError = false;
    resentConfirmation = false;

    constructor(
        private fb: FormBuilder,
        private authentication: AuthenticationFacade,
        private resendEmailVerificationEmailService: ResendVerificationEmailServiceService
    ) {
        this.authentication.reset();
        this.loginForm = this.fb.group({
            password: [null, [Validators.required]],
        });

        this.authenticatedSubscription =
            this.authentication.authenticated$.subscribe(authenticated => {
                if (authenticated) {
                    this.completed.next();
                }
            });

        this.authenticatingSubscription = this.authenticating$.subscribe(
            authenticating => {
                if (authenticating) {
                    this.loginForm.disable();
                } else {
                    this.loginForm.enable();
                }
            }
        );
    }

    ngOnInit() {
        switch (this.authenticationType) {
            case 'email':
                this.loginForm.addControl(
                    'email',
                    new FormControl(null, [
                        Validators.required,
                        Validators.email,
                    ])
                );
                break;
            case 'username':
                this.loginForm.addControl(
                    'username',
                    new FormControl(null, [Validators.required])
                );
                break;
            default:
                throw new Error(
                    `Unexpected authentication type '${this.authenticationType}'`
                );
        }
        if(this.siteConfiguration.is_multi_ltm){
            this.loginForm.addControl(
                'ltm_id',
                new FormControl(null, [Validators.required])
            );
        }
    }

    ngOnDestroy() {
        this.authenticatedSubscription.unsubscribe();
        this.authenticatingSubscription.unsubscribe();
    }

    onLogin() {
        this.loginForm.markAllAsTouched();
        if (this.loginForm.invalid) {
            return;
        }

        this.authentication.login(this.loginForm.value);
    }

    onGoToResetPassword() {
        this.navigate.emit({ component: 'request-password-reset' });
    }

    onSignUpAccount() {}

    onResendConfirmationEmail() {
        if (this.isMagayaNetwork) {
            this.navigate.emit({ component: 'resend-confirmation-email' });
        } else {
            const email = this.loginForm.get('email').value;
            this.resendEmailVerificationEmailService
                .resendVerificationEmail(email)
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                .subscribe(_ => (this.resentConfirmation = true));
        }
    }

    setSelectedLtmId($event: any) {
        localStorage.setItem(
            'network_id',
            $event.value
        );
    }
}
