<div class="card-header">
    <div class="d-flex">
        <div class="flex-grow-1">
            <div class="font-weight-bold">{{ conversation.customer.name }}</div>
            <div class="small">{{ conversation.customer.email }} | {{ conversation.customer.team_name }}</div>
        </div>
        <div class="pr-1">
            <ng-container *ngFor="let participant of conversation.participants">
                <img class="participant-avatar rounded-circle border" alt="Avatar"
                     (mouseenter)="headerUserOP.show($event)"
                     (mouseleave)="headerUserOP.hide()"
                     [src]="'https://www.gravatar.com/avatar/' + (participant.email | md5) + '?d=https%3A%2F%2Fui-avatars.com%2Fapi%2F/' + (participant.name | urlEncode) + '/32/097aff/ffffff/2//false/true/true'">
                <p-overlayPanel #headerUserOP>
                    <qwyk-user-info-tooltip [user]="participant"></qwyk-user-info-tooltip>
                </p-overlayPanel>
            </ng-container>
        </div>
    </div>
    <hr class="my-1">
    <div>{{ 'portal.conversations.subject' | translate }}: {{ conversation.subject }}</div>
</div>

<div #messageContainer class="flex-grow-1" style="overflow-y: auto;">
    <div class="card-body">
        <qwyk-conversation-message *ngFor="let message of conversation.messages; let idx = index" [index]="idx"
            [message]="message" [isCurrentUser]="message.sender.id === currentUser.id"
            (downloadAttachment)="onDownloadAttachment($event)" [downloadingMessageId]="downloadingMessageId"
            [showHeader]="conversation.messages[idx-1]?.sender.id !== message.sender.id || (message.created_at | amDifference:conversation.messages[idx-1]?.created_at:'m') > 60">
        </qwyk-conversation-message>
    </div>
</div>

<ng-container *ngIf="isUserParticipant; else joinConversation">
    <form [formGroup]="messageForm" (submit)="onPostMessage()">
        <textarea id="message" name="message" formControlName="message" rows="3"
            class="form-control rounded-0 border-left-0 border-right-0 border-bottom-0 px-3 py-2"
            placeholder="{{ 'portal.conversations.message-placeholder' | translate }}" maxlength="2000" (keydown)="onMessageKeyDown($event)"></textarea>
        <div *ngIf="attachmentError" class="p-2 text-danger small">
            {{ attachmentError }}
        </div>
        <div *ngIf="messageForm.get('file').value; let file;" class="p-2">
            <fa-icon [icon]="['far', 'paperclip']" class="mr-1"></fa-icon>
            {{ file.name }}
            <button class="btn py-1 px-1 ml-1" (click)="onRemoveAttachment()">
                <fa-icon [icon]="['far', 'times']" size="sm"></fa-icon>
            </button>
        </div>
        <div class="card-footer py-2 text-right">
            <ng-container>
                <input id="attachment" name="attachment" type="file" class="d-none"
                    (change)="onAttachmentSelected($event)" />
                <label class="btn btn-light btn-sm mr-1 mt-2" for="attachment">
                    <fa-icon [icon]="['far', 'paperclip']"></fa-icon>
                </label>
            </ng-container>
            <button class="btn btn-primary btn-sm" type="submit"
                [disabled]="messageForm.disabled || messageForm.invalid">
                <fa-icon [icon]="['far', !busy ? 'paper-plane' : 'spinner']" [pulse]="busy" class="mr-1"></fa-icon>{{ 'portal.conversations.send' | translate }}
            </button>
        </div>
    </form>
</ng-container>

<ng-template #joinConversation>
    <div class="card-footer py-4 text-center">
        <p>{{ 'portal.conversations.join-to-contribute' | translate }}</p>
        <button class="btn btn-primary" (click)="onJoinConversation()" [disabled]="busy">
            <fa-icon [icon]="['far', 'spinner']" [pulse]="true" class="mr-1" *ngIf="busy"></fa-icon>
            {{ 'portal.conversations.join-conversation' | translate }}
        </button>
    </div>
</ng-template>
