import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NgClass, NgIf, NgSwitch, NgSwitchCase, NgSwitchDefault } from '@angular/common';

import { MomentModule } from 'ngx-moment';
import { TooltipModule } from 'primeng/tooltip';
import { TranslateModule } from '@ngx-translate/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

import { Portals } from '@qwyk/models';

import { FileExtensionIconPipe, LineBreaksPipe, PrettyBytesPipe } from '../../../pipes';

@Component({
    standalone: true,
    imports: [NgIf, NgClass, NgSwitch, NgSwitchCase, NgSwitchDefault, PrettyBytesPipe, FileExtensionIconPipe, LineBreaksPipe, FontAwesomeModule, TooltipModule, TranslateModule, MomentModule],
    selector: 'qwyk-conversation-message',
    templateUrl: './conversation-message.component.html',
    styleUrls: ['./conversation-message.component.scss'],
})
export class ConversationMessageComponent {
    @Input() message: Portals.ConversationMessage;
    @Input() showHeader = true;
    @Input() isCurrentUser: boolean;
    @Input() index: number;
    @Input() downloadingMessageId: string | null;

    @Output() downloadAttachment: EventEmitter<{
        messageId: string;
    }> = new EventEmitter();

    onDownloadAttachment() {
        this.downloadAttachment.emit({ messageId: this.message._id });
    }
}
