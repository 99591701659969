<div *ngIf="showHeader" [ngClass]="{'mt-4': index> 0}">
    <span class="font-weight-bold">{{ message.sender.name }}</span> <span
        class="badge badge-outline-primary badge-pill ml-1" *ngIf="isCurrentUser">{{ 'portal.conversations.you' | translate }}</span>
    &bull;
    <span class="text-muted">{{ message.created_at | amTimeAgo }}</span>
</div>

<div class="mb-2">
    <ng-container [ngSwitch]="message.type">
        <span *ngSwitchCase="'joined-conversation'" class="badge badge-outline-dark badge-pill mt-1">
            {{ 'portal.conversations.joined-conversation' | translate }}
        </span>

        <div *ngSwitchCase="'message-with-attachment'">
            <div [innerHtml]="message.message | lineBreaks" class="mb-1"
                [pTooltip]="message.sender.name + ': ' + (message.created_at | amDateFormat: 'L LTZ')"
                tooltipPosition="left"></div>
            <div class="small text-muted">{{ 'common.attachment' | translate }}</div>
            <div class="card overflow-hidden" style="max-width: 400px;">
                <div class="card-body p-0 d-flex align-items-center justify-content-start">
                    <div class="px-2 py-3 bg-primary text-color-contrast-primary text-white">
                        <fa-icon [icon]="['far', message.attachment.extension | fileExtensionIcon]" [fixedWidth]="true"
                            size="2x">
                        </fa-icon>
                    </div>
                    <div class="px-2 overflow-hidden flex-grow-1">
                        <div class="font-weight-bold text-truncate">{{ message.attachment.filename }}</div>
                        <div>{{ message.attachment.file_size_bytes | prettyBytes }}</div>
                    </div>
                    <div>
                        <button class="btn pr-3" (click)="onDownloadAttachment()" [disabled]="downloadingMessageId">
                            <fa-icon
                                [icon]="['far', downloadingMessageId !== message._id ? 'cloud-download' : 'spinner']"
                                size="lg" [pulse]="downloadingMessageId === message._id"></fa-icon>
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div *ngSwitchDefault [innerHtml]="message.message | lineBreaks"
            [pTooltip]="message.sender.name + ': ' + (message.created_at | amDateFormat: 'L LTZ')" tooltipPosition="left">
        </div>
    </ng-container>
</div>

