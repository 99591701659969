/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, inject, Input } from '@angular/core';

import { AuthenticationFacade } from '@qwyk/portals/authentication';
import { ShipmentContainersFacade } from '@qwyk/shared-stores/shipment-containers';

@Component({
    selector: 'qwyk-shipment-containers-view',
    templateUrl: './shipment-containers-view.component.html',
    styleUrls: ['./shipment-containers-view.component.scss'],
})
export class ShipmentContainersViewComponent {

    private readonly auth = inject(AuthenticationFacade);
    private readonly shipmentContainers = inject(ShipmentContainersFacade);

    dateFormat$ = this.auth.dateFormat$;
    loading$ = this.shipmentContainers.loading$;
    shipmentContainers$ = this.shipmentContainers.allShipmentContainers$;

    private _shipment: any;

    public get shipment(): any {
        return this._shipment;
    }

    @Input()
    public set shipment(shipment: any) {
        this._shipment = shipment;
        this.shipmentContainers.init(shipment);
    }
}
