import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

import * as fromAuthentication from './state/authentication.reducer';
import { AuthenticationFacade } from './state/authentication.facade';
import { AuthenticationEffects } from './state/authentication.effects';
import { AuthenticationService } from './services/authentication.service';
import { Auth0CallbackComponent } from './auth0-callback/auth0-callback.component';

@NgModule({
    imports: [
        CommonModule,
        FontAwesomeModule,
        StoreModule.forFeature(
            fromAuthentication.AUTHENTICATION_FEATURE_KEY,
            fromAuthentication.reducer
        ),
        EffectsModule.forFeature([AuthenticationEffects]),
    ],
    declarations: [Auth0CallbackComponent],
    providers: [AuthenticationService, AuthenticationFacade],
})
export class HubAuthenticationWithoutRoutingModule {}
