import { Country } from '../masterdata';

export interface CustomerUser {
    id: string;
    name: string;
    username: string;
    email: string;
    team_id: string;
    team_name: string;
    phone_number: string;
    job_title: string;
    company_name: string;
    location: string;
    country: Country;
    country_code: string;
    registered_site_id: number;
    restrict_to_registered_site: boolean;
    marketing_optin: boolean;
    email_verified_at: string;
    email_verified: boolean;
    approved_at: string;
    approved: boolean;
    approved_by: string;
    last_login_at: null;
    created_at: string;
    deactivated: boolean;
}
