<form *ngIf="!success" class="pt-1" [formGroup]="resetPasswordForm" (submit)="onResetPasword()">
    <h5 class="mb-3 font-weight-normal">{{ 'portal.login-modal.password-reset.title' | translate }}</h5>
    <div role="alert" class="alert alert-info border-info d-flex align-items-center"
         *ngIf="(!resetPasswordForm.get('email').valid || !resetPasswordForm.get('reset_code').valid) && !busy">
        <fa-icon [icon]="['far', 'exclamation-circle']" class="mr-3 text-info"></fa-icon>
        <span>
            {{ 'portal.login-modal.password-reset.helper' | translate }}
        </span>
    </div>
    <ng-container *ngIf="error">
        <ng-container [ngSwitch]="error.status">
            <div *ngSwitchCase="400" role="alert" class="alert alert-danger border-danger d-flex align-items-center">
                <fa-icon [icon]="['far', 'exclamation-circle']" class="mr-3 text-danger"></fa-icon>
                <span>
                    {{ 'portal.login-modal.password-reset.label-email-error-prefix' | translate }}
                    <a (click)="onGoToRequestPasswordReset()"
                       href="javascript:void(0)">{{ 'portal.login-modal.password-reset.label-error-action' | translate }}</a>
                    {{ 'portal.login-modal.password-reset.label-error-suffix' | translate }}
                </span>
            </div>
            <div *ngSwitchDefault role="alert" class="alert alert-danger border-danger d-flex align-items-center">
                <fa-icon [icon]="['far', 'exclamation-circle']" class="mr-3 text-danger"></fa-icon>
                <span>
                    {{ 'common.generic-error' | translate }}
                </span>
            </div>
        </ng-container>
    </ng-container>
    <div class="form-group text-left">
        <label for="email">
            {{ 'portal.login-modal.request-password-reset.label-email' | translate }}
            <span class="text-primary"
                  [ngClass]="{'text-danger': resetPasswordForm.get('email').invalid && resetPasswordForm.get('email').touched}">
                *
            </span>
        </label>
        <input id="email" name="email" type="text" class="form-control form-control-lg form-control-text"
               [attr.placeholder]="'portal.login-modal.request-password-reset.placeholder-email' | translate"
               autocomplete="email" formControlName="email"
               [ngClass]="{'is-invalid': resetPasswordForm.get('email').invalid && resetPasswordForm.get('email').touched}"/>
        <div class="invalid-feedback">
            <div *ngIf="resetPasswordForm.get('email').errors?.required">{{ 'portal.login-modal.request-password-reset.warning-required-email' | translate }}</div>
            <div *ngIf="resetPasswordForm.get('email').errors?.email">{{ 'portal.login-modal.request-password-reset.warning-invalid-email' | translate }}</div>
        </div>
    </div>
    <div class="form-group text-left">
        <label for="reset_code">
            {{ 'portal.login-modal.password-reset.label-code' | translate }}
            <span class="text-primary"
                  [ngClass]="{'text-danger': resetPasswordForm.get('reset_code').invalid && resetPasswordForm.get('reset_code').touched}">
                *
            </span>
        </label>
        <input id="reset_code" name="reset_code" formControlName="reset_code" type="number"
               [attr.placeholder]="'portal.login-modal.password-reset.placeholder-code' | translate" autocomplete="off"
               class="form-control form-control-lg"
               [ngClass]="{'is-invalid': resetPasswordForm.get('reset_code').invalid && resetPasswordForm.get('reset_code').touched}"/>
        <div class="invalid-feedback">
            <div *ngIf="resetPasswordForm.get('reset_code').errors?.required">{{ 'portal.login-modal.password-reset.label-warning-code-required' | translate }}</div>
            <div
                    *ngIf="resetPasswordForm.get('reset_code').errors?.min || resetPasswordForm.get('reset_code').errors?.max">
                {{ 'portal.login-modal.password-reset.label-warning-code-length' | translate :  {'length': 5} }}
            </div>
        </div>
    </div>
    <ng-container *ngIf="(resetPasswordForm.get('email').valid && resetPasswordForm.get('reset_code').valid) || busy">
        <div class="form-group text-left">
            <label for="password">
                {{ 'portal.login-modal.password-reset.label-new-password' | translate }}
                <span class="text-primary"
                      [ngClass]="{'text-danger': resetPasswordForm.get('password').invalid && resetPasswordForm.get('password').touched}">
                    *
                </span>
            </label>
            <div class="input-group"
                 [ngClass]="{'is-invalid': resetPasswordForm.get('password').touched && resetPasswordForm.get('password').invalid}">
                <input [attr.placeholder]="'portal.login-modal.password-reset.placeholder-new-password' | translate"
                       [attr.type]="newPasswordMasked ? 'password' : 'text'" class="form-control form-control-lg"
                       formControlName="password" id="password" name="password"
                       [ngClass]="{'is-invalid': resetPasswordForm.get('password').touched && resetPasswordForm.get('password').invalid}"
                       autocomplete="new-password"/>
                <div class="input-group-append">
                    <button class="btn btn-light border" (click)="newPasswordMasked = !newPasswordMasked" tabindex="-1">
                        <fa-icon [icon]="['far', newPasswordMasked ? 'eye' : 'eye-slash']"></fa-icon>
                    </button>
                </div>
            </div>
            <div class="invalid-feedback" *ngIf="resetPasswordForm.get('password').errors">
                <div *ngIf="resetPasswordForm.get('password').errors['required']">
                    {{ 'portal.login-modal.password-reset.label-warning-password-required' | translate }}
                </div>
                <div *ngIf="resetPasswordForm.get('password').errors['minlength']">
                    {{ 'portal.login-modal.password-reset.label-warning-password-minlength' | translate : {'minlength': resetPasswordForm.get('password').errors['minlength']['requiredLength']} }}

                </div>
                <div *ngIf="resetPasswordForm.get('password').errors['maxlength']">
                    {{ 'portal.login-modal.password-reset.label-warning-password-maxlength' | translate : {'maxlength': resetPasswordForm.get('password').errors['maxlength']['requiredLength']} }}
                </div>
                <div *ngIf="resetPasswordForm.get('password').errors['pattern']">
                    {{ 'portal.login-modal.password-reset.label-warning-password-allowed-characters' | translate }}: A-Z
                    a-z 0-9 ! @ # $ % & - . * =
                </div>
            </div>
        </div>
        <div class="form-group text-left">
            <label for="password_confirmation">Confirm Password
                <span class="text-primary"
                    [ngClass]="{'text-danger': resetPasswordForm.get('password_confirmation').invalid && resetPasswordForm.get('password_confirmation').touched}">
                    *
                </span>
            </label>
            <div class="input-group"
                [ngClass]="{'is-invalid': resetPasswordForm.get('password_confirmation').touched && resetPasswordForm.get('password_confirmation').invalid}">
                <input id="password_confirmation" name="password_confirmation"
                       [attr.type]="confirmPasswordMasked ? 'password' : 'text'" formControlName="password_confirmation"
                       [attr.placeholder]="'portal.login-modal.password-reset.placeholder-confirm-password' | translate"
                       autocomplete="new-password" class="form-control form-control-lg"
                       [ngClass]="{'is-invalid': resetPasswordForm.get('password_confirmation').touched && resetPasswordForm.get('password_confirmation').invalid}"/>
                <div class="input-group-append">
                    <button class="btn btn-light border" (click)="confirmPasswordMasked = !confirmPasswordMasked"
                        tabindex="-1">
                        <fa-icon [icon]="['far', confirmPasswordMasked ? 'eye' : 'eye-slash']"></fa-icon>
                    </button>
                </div>
            </div>
            <div class="invalid-feedback" *ngIf="resetPasswordForm.get('password_confirmation').errors">
                <div *ngIf="resetPasswordForm.get('password_confirmation').errors['required']">
                    {{ 'portal.login-modal.password-reset.label-warning-password-confirm-required' | translate }}
                </div>
                <div *ngIf="resetPasswordForm.get('password_confirmation').errors['matchOther']">
                    {{ 'portal.login-modal.password-reset.label-warning-password-confirm-match' | translate }}
                </div>
            </div>
        </div>
    </ng-container>
    <div class="form-group">
        <button class="btn btn-primary btn-block" type="submit" [disabled]="busy">
            <fa-icon [icon]="['far', 'spinner']" animation="spin-pulse" *ngIf="busy" class="mr-1">
            </fa-icon>
            {{ 'portal.login-modal.password-reset.button-reset-password' | translate }}
        </button>
    </div>
    <div class="text-center">
        <button class="btn btn-link text-primary btn-sm" type="button" [disabled]="busy" (click)="onGoToLogin()">
            <fa-icon [icon]="['far', 'arrow-left']"></fa-icon>
            {{ 'portal.login-modal.password-reset.button-continue-signin' | translate }}
        </button>
    </div>
</form>
<div *ngIf="success" class="text-center">
    <h5 class="mb-3 font-weight-normal">{{ 'portal.login-modal.password-reset.message-success' | translate }}</h5>
    <button (click)="onGoToLogin()" class="btn btn-primary"
            type="button">{{ 'portal.login-modal.password-reset.button-continue-signin' | translate }}
        <fa-icon
                [icon]="['far', 'arrow-right']"></fa-icon>
    </button>
</div>
