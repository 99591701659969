<div class="min-vh-100 bg-light d-flex align-items-center justify-content-center">
    <div class="card shadow-sm" style="max-width: 500px;">
        <div class="card-body">
            <h4 class="font-weight-normal mb-4">
                <fa-icon [fixedWidth]="true" [icon]="icon" class="text-danger mr-2"></fa-icon>
                {{label}}
            </h4>
            <p class="mb-4 font-weight-bold">{{ description }}</p>
            <small>
                <div>Resource ID: {{ resource }}</div>
                <div class="mb-4">Error Code: {{ errorCode }}</div>
            </small>
            <a [routerLink]="resource" class="mr-1">Try again</a>
            |
            <a [routerLink]="['/my-portal']" class="ml-1">Back home</a>
        </div>
    </div>
</div>
