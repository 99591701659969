export interface PaginatedResponse<T> {
    data: T[];
    links: PaginationLinks;
    meta: PaginationMeta;
    extra?: any;
}

export interface CursorPaginatedResponse<T> {
    data: T[];
    meta: CursorPaginationMeta;
    extra?: any;
}

export interface Pagination<T> {
    data: T[];
    links: PaginationLinks;
    meta: PaginationMeta;
    extra?: any;
}

export interface PaginationLinks {
    first: string | null;
    last: string | null;
    prev: string | null;
    next: string | null;
}

export interface PaginationMeta {
    current_page: number;
    from: number;
    last_page: number;
    path: number | string;
    per_page: number;
    to: number;
    total: number;
}

export interface CursorPaginationMeta {
    cursor: string | null;
    cursor_name: string;
    page_size: number;
    count: number;
}
