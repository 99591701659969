import {
    FormBuilder,
    FormGroup,
    FormsModule,
    ReactiveFormsModule,
} from '@angular/forms';
import { CommonModule, NgIf } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import {
    ReactiveValidationModule,
    Validators,
} from 'angular-reactive-validation';
import { tap } from 'rxjs/operators';
import { SidebarModule } from 'primeng/sidebar';
import { TabViewModule } from 'primeng/tabview';
import { DropdownModule } from 'primeng/dropdown';
import { Table, TableModule } from 'primeng/table';
import { InputTextModule } from 'primeng/inputtext';
import { SelectButtonModule } from 'primeng/selectbutton';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgbModal, NgbModalModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule, TranslateService } from '@ngx-translate/core';

import { common } from '@qwyk/models';
import { TableColumn } from '@qwyk/core';
import { CustomViewsFacade } from '@qwyk/shared-stores/custom-views';

import { SimpleModalPortal } from '../simple-modal-portal/simple-modal-portal.component';
import { CreateCustomViewModalComponent } from '../create-custom-view-modal/create-custom-view-modal.component';

export const DEFAULT_VIEW_ID = '1';

@Component({
    standalone: true,
    imports: [
        NgIf,
        FormsModule,
        TableModule,
        CommonModule,
        SidebarModule,
        TabViewModule,
        DropdownModule,
        NgbModalModule,
        TranslateModule,
        InputTextModule,
        FontAwesomeModule,
        SelectButtonModule,
        ReactiveFormsModule,
        ReactiveValidationModule,
    ],
    selector: 'qwyk-custom-table-views',
    templateUrl: './custom-table-views.component.html',
    styleUrls: ['./custom-table-views.component.scss'],
})
export class CustomTableViewsComponent implements OnInit {
    private _columns: TableColumn[] = [];
    public checkedColumns: TableColumn[] = [];
    public customizeForm!: FormGroup;
    public stateOptions: { label: string; value: boolean }[] = [
        { label: 'No', value: false },
        { label: 'Yes', value: true },
    ];
    public display = false;
    public selectAll = false;
    public selectedView!: common.CustomView;
    public storedViews!: common.CustomView[];
    public DEFAULT_VIEW_ID = DEFAULT_VIEW_ID;
    public DISABLED_COLUMNS!: string[];
    public loading$ = this._customViewsFacade.loading$;
    public selectedView$ = this._customViewsFacade.selectedView$.pipe(
        tap(view => {
            if (view) {
                this.selectedView = view;
                this.customizeForm = this._createForm({
                    ...view,
                    name: view.uid === DEFAULT_VIEW_ID ? '' : view.name,
                });
                this.setCheckedColumns(view);
            }
        })
    );

    private defaultColumns!: string[];

    @Input() resource!: string;
    @Input() set columns(values: TableColumn[]) {
        this._columns = values.map(c => {
            if (c.sub_key && !c.key.includes('custom_fields_dictionary')) {
                return { ...c, key: `${c.key}.${c.sub_key}` };
            }
            return c;
        });
    }
    get columns() {
        return this._columns;
    }

    @Input() set views(values: common.CustomView[]) {
        this.storedViews = values;
        const defaultView = this.storedViews.find(
            view => view.uid === DEFAULT_VIEW_ID
        );
        this.defaultColumns = defaultView?.columns || [];
    }

    @Input() get selectedColumns(): TableColumn[] {
        return [];
    }

    @Output() public rowChange: EventEmitter<TableColumn[]> =
        new EventEmitter();

    constructor(
        private readonly _fb: FormBuilder,
        private readonly _modal: NgbModal,
        private readonly _translate: TranslateService,
        private readonly _customViewsFacade: CustomViewsFacade
    ) {}

    ngOnInit(): void {
        this.DISABLED_COLUMNS = this._columns
            .filter(c => c.disabled)
            .map(c => c.key);

        this.stateOptions.forEach(option => {
            if (!option.value) {
                option.label = this._translate.instant('common.no');
            } else if (option.value) {
                option.label = this._translate.instant('common.yes');
            }
        });

        this.storedViews.forEach(view => {
            if (view.uid === '1') {
                view.name = this._translate.instant(
                    'portal.custom-table-views.labels.default_view'
                );
            }
        });
    }

    public onChangeView($event: string) {
        this.selectedView =
            this.storedViews.find(view => view.uid === $event) ||
            this.storedViews[0];
        this._customViewsFacade.setSelectedView(this.selectedView.uid);
        this.setCheckedColumns(this.selectedView);
        this.customizeForm = this._createForm(this.selectedView);
    }

    public createView() {
        const object = this._prepareFormValue();

        const modalRef = this._modal.open(CreateCustomViewModalComponent, {
            centered: true,
        });

        const component: CreateCustomViewModalComponent =
            modalRef.componentInstance;

        component.inputValue = object.name;

        modalRef.result.then(
            viewName => {
                object.name = viewName;
                object.default_view = false;
                this._create(object);
            },
            () => {
                // Do nothing
            }
        );
        component.inputValue = null;
    }

    public updateView() {
        if (
            this.selectedView.uid !== DEFAULT_VIEW_ID &&
            this.customizeForm.valid
        ) {
            const object = this._prepareFormValue();
            object.uid = this.selectedView.uid;
            this._update(object);
        }
    }

    public onRowReorder(e: Event, view: common.CustomView, table: Table) {
        const columns = this.checkedColumns;
        this.checkedColumns = [];
        table.value.forEach(col => {
            const ids = columns.map(c => c.key);
            if (ids.includes(col.key)) {
                this.checkedColumns.push(col);
            }
        });
        this.rowChange.emit(this.checkedColumns);
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public onSelectionChange(columns: any[], view: any, table: Table) {
        let ids: string[];
        if (columns.length) {
            ids = columns.map(c => c.key);
        } else {
            ids = [...this.DISABLED_COLUMNS];
        }

        this.checkedColumns = table.value.filter(item =>
            ids.includes(item.key)
        );
        this.rowChange.emit(this.checkedColumns);
    }

    public onSelectAllChange(
        event: { originalEvent: Event; checked: boolean },
        view: common.CustomView,
        table: Table
    ) {
        const checked = event.checked;
        let checkedColumns;

        if (checked) {
            const ids = this._columns.map(row => row.key);
            checkedColumns = table.value.filter(item => ids.includes(item.key));
            this.selectAll = true;
        } else {
            const ids = [...this.DISABLED_COLUMNS];
            checkedColumns = table.value.filter(item => ids.includes(item.key));
            this.selectAll = false;
        }
        this.checkedColumns = checkedColumns;

        this.selectedView.columns = checkedColumns;
        this.rowChange.emit(this.checkedColumns);
    }

    public shouldDisableRow(column: TableColumn): boolean {
        return column.disabled || false;
    }

    public onRemoveView(customView: common.CustomView, e: Event) {
        e.stopImmediatePropagation();
        const modalRef = this._modal.open(SimpleModalPortal, {
            centered: true,
        });

        const component: SimpleModalPortal = modalRef.componentInstance;
        component.header = this._translate.instant(
            'portal.custom-table-views.title'
        );
        component.message = this._translate.instant(
            'portal.custom-table-views.delete_confirmation_message'
        );
        component.dismissAction = this._translate.instant('common.cancel');
        component.confirmAction = `${this._translate.instant(
            'common.yes'
        )}, ${this._translate.instant('common.delete')}`;
        component.confirmStyle = 'btn-danger';

        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        modalRef.result.then(_ => {
            this._customViewsFacade.deleteCustomView(this.resource, customView);
            const defaultView = this.storedViews.find(
                view => view.default_view && view.uid !== customView.uid
            );

            this._customViewsFacade.setSelectedView(
                defaultView?.uid ?? DEFAULT_VIEW_ID
            );
        });
    }

    private _update(object: Partial<common.CustomView>) {
        this._customViewsFacade.updateCustomView(this.resource, object);
    }

    private setCheckedColumns(view: common.CustomView) {
        if (view.uid === '1') {
            view.name = this._translate.instant(
                'portal.custom-table-views.labels.default_view'
            );
        }
        if (this._columns?.length > 0) {
            this._columns = this._columns.sort((a, b) => {
                if (
                    view.columns.includes(a.key) &&
                    view.columns.includes(b.key)
                ) {
                    return (
                        view.columns.indexOf(a.key) -
                        view.columns.indexOf(b.key)
                    );
                }
                return 0;
            });
            this.checkedColumns = this._columns.filter(column => {
                return view.columns.includes(column.key);
            });
            const uncheckedColumns = this._columns.filter(column => {
                return !view.columns.includes(column.key);
            });
            this._columns = [...this.checkedColumns, ...uncheckedColumns];
        }
    }

    private _prepareFormValue() {
        return {
            ...this.customizeForm.getRawValue(),
            columns: this.checkedColumns.map(c => c.key),
        };
    }

    private _createForm(view: common.CustomView): FormGroup {
        return this._fb.group({
            name: [
                view.name,
                [
                    Validators.required('Name is required'),
                    Validators.pattern(
                        /^[A-Za-z0-9ñÑáéíóúÁÉÍÓÚ ]+$/,
                        'The name contains invalid characters.'
                    ),
                ],
            ],
            default_view: [view.default_view],
        });
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private _create(object: any) {
        object.parent = this.resource;
        object.columns = this.defaultColumns;
        this._customViewsFacade.addNewCustomView(this.resource, object);
    }
}
