import {
    makeQueryKVP,
    QueryBuilderQueryToken,
    QueryBuilderSortOption,
} from './../../../../controls/components/query-builder/query-builder.component';
import { ActivatedRoute } from '@angular/router';
import { AlgoliaLocationsService } from './../../../../../services/algolia-locations.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import {
    expandAnimation,
    fadeInAnimation,
} from './../../../../../helpers/reusableAnimations';
import { map, tap } from 'rxjs/operators';
import { Observable, Subscription } from 'rxjs';
import { ShipmentsService } from './../../../services/shipments.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SimpleModalPortal } from '@qwyk/ui-standalone';

@Component({
    selector: 'qw-shipments-list',
    templateUrl: './shipments-list.component.html',
    styleUrls: ['./shipments-list.component.scss'],
    animations: [expandAnimation, fadeInAnimation],
})
export class ShipmentsListComponent implements OnInit, OnDestroy {
    constructor(
        private service: ShipmentsService,
        private algoliaLocations: AlgoliaLocationsService,
        private route: ActivatedRoute,
        private modalService: NgbModal
    ) {}
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    shipments$: Observable<any[]>;
    searchValue = {};
    loadingShipments = true;
    queryBuilderSuggestions: { key: string; label: string }[] = [];
    loadingQueryBuilderSuggestions = false;
    queryBuilderDisabled = false;
    queryBuilderQueryTokens: QueryBuilderQueryToken[] = [];
    recentSearches$ = this.service.getRecentSearches();
    queryBuilderTokens = [
        {
            key: 'author',
            label: 'Author',
            icon: ['far', 'user'],
            exclusive: true,
        },
        {
            key: 'milestone',
            label: 'Milestone',
            icon: ['far', 'clock'],
            exclusive: false,
        },

        {
            key: 'origin',
            label: 'Origin',
            icon: ['far', 'map-pin'],
            exclusive: true,
        },

        {
            key: 'destination',
            label: 'Destination',
            icon: ['far', 'map-pin'],
            exclusive: true,
        },
    ];
    queryBuilderSortOptions: QueryBuilderSortOption[] = [
        {
            key: 'updated_at',
            label: 'Last updated',
            direction: 'desc',
            allowChangeDirection: true,
        },
        {
            key: 'created_at',
            label: 'Create date',
            direction: 'desc',
            allowChangeDirection: true,
        },
        // {
        //     key: 'shipment_data.etd',
        //     label: 'ETD',
        //     direction: 'desc',
        //     allowChangeDirection: true,
        // },
        // {
        //     key: 'shipment_data.eta',
        //     label: 'ETA',
        //     direction: 'desc',
        //     allowChangeDirection: true,
        // },
        // {
        //     key: 'shipment_data.vessel',
        //     label: 'Vessel/Flight',
        //     direction: 'asc',
        //     allowChangeDirection: true,
        // },
    ];
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private lastQuery: any | null;
    private queryParamsSubscriptions: Subscription;
    public queryParams$ = this.route.queryParams;

    ngOnInit() {
        this.queryParamsSubscriptions = this.queryParams$.subscribe(params => {
            if (params.action && params.action === 'warning') {
                this.showWarningModal(params.title, params.message);
            }

            this.queryBuilderQueryTokens = [];
            if (params.query_key && params.query_value) {
                this.queryBuilderQueryTokens.push({
                    attribute: {
                        key: params.query_key,
                        label: params.query_key,
                        fulltext: params.query_key === 'freetext',
                    },
                    value: {
                        key: params.query_value,
                        label: params.query_display || params.query_value,
                    },
                });

                this.search(makeQueryKVP(this.queryBuilderQueryTokens));
            } else {
                this.loadingShipments = true;
                this.shipments$ = this.service
                    .getShipments()
                    .pipe(tap(() => (this.loadingShipments = false)));
            }
        });
    }

    ngOnDestroy(): void {
        if (this.queryParamsSubscriptions) {
            this.queryParamsSubscriptions.unsubscribe();
        }
    }

    public search(query) {
        this.lastQuery = query;
        this.loadingShipments = true;
        this.shipments$ = this.service
            .getShipments(query)
            .pipe(tap(() => (this.loadingShipments = false)));
    }

    onPageChange(newPage) {
        this.lastQuery.page = newPage;
        this.search(this.lastQuery);
    }

    public loadQueryBuilderSuggestions(queryKey: {
        key: string;
        query: string;
    }) {
        if (!queryKey) {
            return;
        }

        const suggestions = [
            { key: 'origin.booked', label: 'Booked' },
            { key: 'origin.confirmed', label: 'Confirmed' },
            { key: 'origin.received', label: 'Received' },
            { key: 'origin.departed', label: 'Departed' },
            { key: 'destination.arrived', label: 'Arrived' },
            { key: 'destination.delivered', label: 'Delivered' },
            { key: 'DRAFT', label: 'Draft' },
        ];
        switch (queryKey.key) {
            case 'milestone':
                if (queryKey.query) {
                    this.queryBuilderSuggestions = suggestions.filter(e =>
                        e.label
                            .toLowerCase()
                            .startsWith(queryKey.query.toLowerCase())
                    );
                } else {
                    this.queryBuilderSuggestions = suggestions;
                }
                break;
            case 'origin':
            case 'destination':
                this.loadLocationsFromServer(queryKey.query);
                break;
            default:
                this.queryBuilderSuggestions = [];
                break;
        }
    }

    private loadLocationsFromServer(queryString) {
        if (queryString) {
            this.loadingQueryBuilderSuggestions = true;
            const subscr = this.algoliaLocations
                .getLocationSuggestions(queryString)
                .pipe(
                    map(result => {
                        return result.map(el => {
                            return { key: el.locode, label: el.display_name };
                        });
                    })
                )
                .subscribe(
                    result => {
                        this.queryBuilderSuggestions = result;
                        this.loadingQueryBuilderSuggestions = false;
                        subscr.unsubscribe();
                    },
                    () => {
                        this.queryBuilderSuggestions = [];
                        this.loadingQueryBuilderSuggestions = false;
                        subscr.unsubscribe();
                    }
                );
        }
    }

    private showWarningModal(header, message) {
        const modalRef = this.modalService.open(SimpleModalPortal, {
            centered: true,
        });

        modalRef.componentInstance.header = header;
        modalRef.componentInstance.message = message;
    }
}
