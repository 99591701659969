import {
    Component,
    Input,
    OnInit,
    Output,
    EventEmitter,
    ViewChild,
    ElementRef,
} from '@angular/core';
import { NgFor, NgIf } from '@angular/common';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';

import { MomentModule } from 'ngx-moment';
import * as prettyBytes from 'pretty-bytes';
import { TranslateModule } from '@ngx-translate/core';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

import { Portals } from '@qwyk/models';

import { MD5Pipe, UrlEncodePipe } from '../../../pipes';
import { UserInfoTooltipComponent } from '../../user-info-tooltip/user-info-tooltip.component';
import { ConversationMessageComponent } from '../conversation-message/conversation-message.component';

@Component({
    standalone: true,
    imports: [
        NgIf,
        NgFor,
        MD5Pipe,
        UrlEncodePipe,
        ConversationMessageComponent,
        UserInfoTooltipComponent,
        ReactiveFormsModule,
        OverlayPanelModule,
        FontAwesomeModule,
        MomentModule,
        TranslateModule,
    ],
    selector: 'qwyk-conversation-detail',
    templateUrl: './conversation-detail.component.html',
    styleUrls: ['./conversation-detail.component.scss'],
})
export class ConversationDetailComponent implements OnInit {
    private _conversation: Portals.Conversation;
    private _busy = false;
    private maxUploadSize = 2000000;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public attachmentError: any;

    @Input()
    public set conversation(conversation: Portals.Conversation) {
        this.onConversationChanging(conversation);
        this._conversation = conversation;
        this.onConversationChanged();
    }
    public get conversation(): Portals.Conversation {
        return this._conversation;
    }

    @Input()
    public set busy(busy: boolean) {
        this._busy = busy;
        this.onBusyChanged();
    }
    public get busy(): boolean {
        return this._busy;
    }

    @Input()
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public currentUser: any;

    @Input() downloadingMessageId: string | null = null;

    @Output()
    joinConversation: EventEmitter<Portals.Conversation> = new EventEmitter();

    @Output()
    postMessage: EventEmitter<{
        conversation: Portals.Conversation;
        message: string;
        file?: File;
    }> = new EventEmitter();

    @Output()
    downloadAttachment: EventEmitter<{
        conversationId: string;
        messageId: string;
    }> = new EventEmitter();

    @ViewChild('messageContainer', { static: true })
    private messageContainer: ElementRef;

    public messageForm: FormGroup;

    constructor(private fb: FormBuilder) {
        this.messageForm = this.fb.group({
            message: [null, [Validators.required]],
            file: [null],
        });
    }

    ngOnInit(): void {
        this.scrollToBottom();
    }

    /**
     * Gets a boolean indicating whether the current user is a participant to this conversation.
     */
    public get isUserParticipant(): boolean {
        return (
            this.conversation &&
            this.currentUser &&
            this.conversation.participants.findIndex(
                e => e.id === this.currentUser.id
            ) > -1
        );
    }

    /**
     * Handles the user requesting to join the conversation.
     */
    public onJoinConversation(): void {
        this.joinConversation.emit(this.conversation);
    }

    /**
     * Handles the user posting a new message.
     */
    public onPostMessage(): void {
        if (this.messageForm.invalid) {
            return;
        }

        const message = this.messageForm.get('message')?.value;
        const file = this.messageForm.get('file')?.value;

        this.postMessage.emit({
            conversation: this.conversation,
            message,
            file,
        });
    }

    /**
     * Resets the messageForm to its original state
     */
    public resetForm() {
        this.messageForm.reset();
    }

    public onAttachmentSelected(e) {
        this.attachmentError = null;
        let file = null;
        if (e.target.files.length > 0) {
            file = e.target.files[0];
        } else {
            return;
        }

        if (this.maxUploadSize && file.size > this.maxUploadSize) {
            this.attachmentError = `This file is too large, maximum size: ${prettyBytes(
                this.maxUploadSize
            )}, file: ${prettyBytes(file.size)}`;
            return;
        }

        this.messageForm.get('file')?.setValue(file);
    }

    public onRemoveAttachment() {
        this.messageForm.get('file')?.reset();
    }

    public onDownloadAttachment(event: { messageId: string }) {
        this.downloadAttachment.emit({
            ...event,
            conversationId: this.conversation._id,
        });
    }

    /**
     * Forces the messageContainer element to scroll to the bottom
     */
    private scrollToBottom(): void {
        try {
            setTimeout(() => {
                if (this.messageContainer) {
                    this.messageContainer.nativeElement.scrollTop =
                        this.messageContainer.nativeElement.scrollHeight;
                }
            }, 0);
        } catch (err) {
            console.error(err);
        }
    }

    /**
     * Triggered when the conversation property is about to change
     * @param newConversation the new conversation
     */
    private onConversationChanging(newConversation: Portals.Conversation) {
        if (
            this.conversation &&
            newConversation &&
            newConversation._id !== this.conversation._id
        ) {
            this.messageForm.reset();
            this.attachmentError = null;
        }
    }

    /**
     * Triggered when the conversation property has changed.
     */
    private onConversationChanged() {
        this.scrollToBottom();
    }

    /**
     * Triggered when the busy property has changed.
     */
    private onBusyChanged() {
        // If marked as busy, we'll disable to messageForm and vice-versa.
        setTimeout(() => {
            if (this.busy) {
                this.messageForm.disable();
            } else {
                this.messageForm.enable();
            }
        }, 0);
    }

    public onMessageKeyDown(e: KeyboardEvent) {
        if (e.key === 'Enter' && e.shiftKey) {
            this.onPostMessage();
        }
    }
}
