import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';

import { StoreModule } from '@ngrx/store';
import { MomentModule } from 'ngx-moment';
import { EffectsModule } from '@ngrx/effects';
import { TranslateModule } from '@ngx-translate/core';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgbPaginationModule, NgbProgressbarModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';

import {
    BreadcrumbsComponent,
    DateDiffInPercentPipe,
} from '@qwyk/ui-standalone';
import { PortalsOnboardingModule } from '@qwyk/portals/onboarding';

import { SharedModule } from './../shared/shared.module';
import { DashboardRoutingModule } from './dashboard-routing.module';
import { DashboardEffects } from './store/effects/dashboard.effects';
import { dashboardReducer } from './store/reducers/dashboard.reducers';
import { OpsDashboardComponent } from './components/ops-dashboard/ops-dashboard.component';
import { WidgetSelectorComponent } from './components/widget-selector/widget-selector.component';
import { ListViewWidgetComponent } from './components/widgets/list-view-widget/list-view-widget.component';
import { ImprovedDashboardComponent } from './components/improved-dashboard/improved-dashboard.component';
import { SchedulesDashboardComponent } from './components/schedules-dashboard/schedules-dashboard.component';
import { ProcurementDashboardComponent } from './components/procurement-dashboard/procurement-dashboard.component';

@NgModule({
    declarations: [
        OpsDashboardComponent,
        WidgetSelectorComponent,
        ListViewWidgetComponent,
        ImprovedDashboardComponent,
        SchedulesDashboardComponent,
        ProcurementDashboardComponent,
    ],
    imports: [
        CommonModule,
        FormsModule,
        SharedModule,
        MomentModule,
        NgSelectModule,
        NgxChartsModule,
        TranslateModule,
        NgxChartsModule,
        NgbTooltipModule,
        FontAwesomeModule,
        NgbPaginationModule,
        BreadcrumbsComponent,
        NgbProgressbarModule,
        DateDiffInPercentPipe,
        DashboardRoutingModule,
        PortalsOnboardingModule,
        StoreModule.forFeature('dashboard', dashboardReducer),
        EffectsModule.forFeature([DashboardEffects]),
    ],
})
export class DashboardModule {}
