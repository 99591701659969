<div class="card">
    <div class="card-body">
        <form [formGroup]="form">
            <div class="form-row">
                <div class="form-group col-md-6 col-xl-12 p-fluid">
                    <label for="carrier">{{ 'portal.schedules.search.carriers' | translate }}</label>
                    <p-multiSelect
                        [options]="carriers"
                        appendTo="body"
                        formControlName="carrier"
                        id="carrier"
                        placeholder="{{ 'portal.schedules.search.carriers' | translate }}"
                        [overlayOptions]="{
                            hideTransitionOptions: '0ms',
                            showTransitionOptions: '0ms'
                        }"
                        optionLabel="display_name"
                        optionValue="code"
                    ></p-multiSelect>
                </div>
                <div class="form-group col-md-6 col-xl-12 p-fluid">
                    <label for="vessel">{{ 'portal.schedules.search.vessels' | translate }}</label>
                    <p-multiSelect
                        [options]="vessels"
                        appendTo="body"
                        formControlName="vessel"
                        id="vessel"
                        placeholder="{{ 'portal.schedules.search.vessels' | translate }}"
                        [overlayOptions]="{
                            hideTransitionOptions: '0ms',
                            showTransitionOptions: '0ms'
                        }"
                    ></p-multiSelect>
                </div>
                <div class="form-group col-md-6 col-xl-12 p-fluid">
                    <label for="voyage">{{ 'portal.schedules.search.voyages' | translate }}</label>
                    <p-multiSelect
                        [options]="voyages"
                        appendTo="body"
                        formControlName="voyage"
                        id="voyage"
                        placeholder="{{ 'portal.schedules.search.voyages' | translate }}"
                        [overlayOptions]="{
                            hideTransitionOptions: '0ms',
                            showTransitionOptions: '0ms'
                        }"
                    ></p-multiSelect>
                </div>
                <div class="form-group col-md-6 col-xl-12 p-fluid">
                    <label for="etd">{{ 'portal.schedules.search.etd-between' | translate }}</label>
                    <p-calendar
                        [readonlyInput]="true"
                        formControlName="etd"
                        id="etd"
                        qwykPrimengCalendarFix
                        [dateFormatValue]="(calendarDateFormat$ | async)?.toLowerCase()"
                        placeholder="{{ 'portal.schedules.search.etd-between' | translate }}"
                        selectionMode="range"
                        [showButtonBar]="true"
                    ></p-calendar>
                </div>
                <div class="form-group col-md-6 col-xl-12 p-fluid">
                    <label for="eta">{{ 'portal.schedules.search.eta-between' | translate }}</label>
                    <p-calendar
                        [readonlyInput]="true"
                        formControlName="eta"
                        id="eta"
                        qwykPrimengCalendarFix
                        [dateFormatValue]="(calendarDateFormat$ | async)?.toLowerCase()"
                        placeholder="{{ 'portal.schedules.search.eta-between' | translate }}"
                        selectionMode="range"
                        [showButtonBar]="true"
                    ></p-calendar>
                </div>
                <div class="form-group col-md-6 col-xl-12 p-fluid">
                    <label for="closing">{{ 'portal.schedules.search.closing-between' | translate }}</label>
                    <p-calendar
                        [readonlyInput]="true"
                        formControlName="closing"
                        id="closing"
                        qwykPrimengCalendarFix
                        [dateFormatValue]="(calendarDateFormat$ | async)?.toLowerCase()"
                        placeholder="{{ 'portal.schedules.search.closing-between' | translate }}"
                        selectionMode="range"
                        [showButtonBar]="true"
                    ></p-calendar>
                </div>
            </div>
            <div class="form-group text-right mb-0">
                <button
                    class="btn btn-primary"
                    type="submit"
                    [disabled]="disabled"
                    (click)="onSearch()"
                >
                    {{ 'common.search' | translate }}
                </button>
                <button
                    [disabled]="disabled"
                    class="btn ml-2"
                    type="reset"
                    (click)="onReset()">
                    {{ 'common.reset' | translate }}
                </button>
            </div>
        </form>
    </div>
</div>


