import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Route, RouterModule } from '@angular/router';

import { Auth0CallbackComponent } from './auth0-callback/auth0-callback.component';
import { HubAuthenticationWithoutRoutingModule } from './hub-authentication-without-routing.module';

const routes: Route[] = [
    { path: '', pathMatch: 'full', component: Auth0CallbackComponent },
];

@NgModule({
    imports: [
        CommonModule,
        HubAuthenticationWithoutRoutingModule,
        RouterModule.forChild(routes),
    ],
    declarations: [],
    providers: [],
})
export class HubAuthenticationModule {}
