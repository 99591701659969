import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    standalone: true,
    name: 'arrayFilterByObject',
})
export class ArrayFilterByObjectPipe implements PipeTransform {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    transform(value: any[], ...args: any[]): any[] {
        const fieldName = args[0];
        const subKey = args[1];
        const fieldValue = args[2];
        const ignoreNull = args[3];

        if (!value) {
            return value;
        }

        if (ignoreNull === true && fieldValue === null) {
            return value;
        }

        return value.filter(e => e[fieldName][subKey] == fieldValue);
    }
}
