import { NgClass, NgIf } from '@angular/common';
import { Component, Input } from '@angular/core';

import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgbDropdownModule, NgbModal, NgbModalModule } from '@ng-bootstrap/ng-bootstrap';

import { VimeoPlayerModalComponent } from '../vimeo-player-modal/vimeo-player-modal.component';
import { TranslateModule } from '@ngx-translate/core';

@Component({
    standalone: true,
    imports: [NgIf, NgClass, NgbDropdownModule, NgbModalModule, FontAwesomeModule, TranslateModule],
    selector: 'qwyk-module-info-button',
    templateUrl: './module-info-button.component.html',
    styleUrls: ['./module-info-button.component.scss'],
})
export class ModuleInfoButtonComponent {
    @Input() documentationUrl: string | null = null;
    @Input() vimeoVideoId: number | null = null;
    @Input() buttonClass: string | null = null;

    constructor(private modal: NgbModal) {}

    onShowTutorialVideo() {
        const component = this.modal.open(VimeoPlayerModalComponent, {
            centered: true,
            size: 'xl',
        }).componentInstance as VimeoPlayerModalComponent;

        if (this.vimeoVideoId) {
            component.playVideo(this.vimeoVideoId);
        }
    }
}
