import { FormsModule } from '@angular/forms';
import { Component, inject, OnInit } from '@angular/core';
import { NgClass, NgIf, NgSwitch } from '@angular/common';

import { DynamicDialogConfig, DynamicDialogModule, DynamicDialogRef } from 'primeng/dynamicdialog';

import { LineBreaksPipe } from '../../pipes';

@Component({
    standalone: true,
    imports: [NgIf, NgClass, NgSwitch, FormsModule, LineBreaksPipe, DynamicDialogModule],
    selector: 'qwyk-simple-modal',
    templateUrl: './simple-modal.component.html',
    styleUrls: ['./simple-modal.component.scss'],
})
export class SimpleModalComponent implements OnInit {

    public config = inject(DynamicDialogConfig);
    protected dialogRef = inject(DynamicDialogRef);

    public header!: string;
    public message!: string;
    public dismissAction = 'OK';
    public confirmAction: string | null = null;
    public confirmStyle = 'btn-primary';
    public dismissStyle = null;
    public showInput = false;
    public inputLabel = null;
    public inputPlaceholder = null;
    public inputType: string | null = null;
    public inputValue = null;
    public inputRequired = false;
    public inputHint = null;

    public ngOnInit() {
        if (this.config) {
            Object.assign(this, this.config.data);
        }
    }

    public confirm() {
        if (this.showInput) {
            this.dialogRef.close(this.inputValue);
        } else {
            this.dialogRef.close(true);
        }
    }

    public dismiss() {
        this.dialogRef.close();
    }
}
