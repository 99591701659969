import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GoogleMapsModule } from '@angular/google-maps';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { MenuModule } from 'primeng/menu';
import { MomentModule } from 'ngx-moment';
import { StoreModule } from '@ngrx/store';
import { ButtonModule } from 'primeng/button';
import { EffectsModule } from '@ngrx/effects';
import { TooltipModule } from 'primeng/tooltip';
import { TranslateModule } from '@ngx-translate/core';
import { NgSelectModule } from '@ng-select/ng-select';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import {
    NgbNavModule,
    NgbPopoverModule,
    NgbTooltipModule,
    NgbDropdownModule,
    NgbPaginationModule,
} from '@ng-bootstrap/ng-bootstrap';

import { PortalsInvoicesModule } from '@qwyk/portals/invoices';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { PortalsBookingWizardModule } from '@qwyk/portals/booking-wizard';
import { PortalsShipmentContainersModule } from '@qwyk/portals/shipment-containers';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { PortalsSchedulesSearchWithoutRoutingModule } from '@qwyk/portals/schedules-search';
import { PortalsShipmentTrackingEventsModule } from '@qwyk/portals/shipment-tracking-events';
import {
    MD5Pipe,
    UrlEncodePipe,
    LineBreaksPipe,
    ObjectToKVPPipe,
    SlugToTitlePipe,
    FileExtensionIconPipe,
    UserInfoTooltipComponent,
    BypassSecurityTrustResourceUrlPipe,
} from '@qwyk/ui-standalone';

import { SharedModule } from '../shared/shared.module';
import { ControlsModule } from '../controls/controls.module';
import { PortalRoutingModule } from './portal-routing.module';
import { ShipmentsEffects } from './store/effects/shipments.effects';
import { shipmentsReducer } from './store/reducers/shipments.reducer';
import { MockPageComponent } from './components/mock-page/mock-page.component';
import { ShipmentComponent } from './components/shipments/shipment/shipment.component';
import { PortalIndexComponent } from './components/portal-index/portal-index.component';
import { ResourceErrorComponent } from './components/resource-error/resource-error.component';
import { SiteWideSearchComponent } from './components/site-wide-search/site-wide-search.component';
import { ShipmentsListComponent } from './components/shipments/shipments-list/shipments-list.component';
import { ShipmentsHistoryComponent } from './components/shipments/shipments-history/shipments-history.component';
import { ShipmentActivityListComponent } from './components/shipments/shipment-activity-list/shipment-activity-list.component';
import { ShipmentsNavbarWidgetComponent } from './components/shipments/shipments-navbar-widget/shipments-navbar-widget.component';
import { AttachShipmentDocumentComponent } from './components/shipments/attach-shipment-document/attach-shipment-document.component';

@NgModule({
    declarations: [
        MockPageComponent,
        ShipmentComponent,
        PortalIndexComponent,
        ResourceErrorComponent,
        ShipmentsListComponent,
        SiteWideSearchComponent,
        ShipmentsHistoryComponent,
        ShipmentActivityListComponent,
        ShipmentsNavbarWidgetComponent,
        AttachShipmentDocumentComponent,
    ],
    imports: [
        MD5Pipe,
        MenuModule,
        FormsModule,
        CommonModule,
        ButtonModule,
        SharedModule,
        MomentModule,
        NgbNavModule,
        UrlEncodePipe,
        TooltipModule,
        ControlsModule,
        LineBreaksPipe,
        NgSelectModule,
        ObjectToKVPPipe,
        SlugToTitlePipe,
        TranslateModule,
        NgbTooltipModule,
        GoogleMapsModule,
        NgbPopoverModule,
        NgbDropdownModule,
        FontAwesomeModule,
        OverlayPanelModule,
        PortalRoutingModule,
        ConfirmDialogModule,
        NgbPaginationModule,
        ReactiveFormsModule,
        FileExtensionIconPipe,
        PortalsInvoicesModule,
        UserInfoTooltipComponent,
        PortalsShipmentContainersModule,
        BypassSecurityTrustResourceUrlPipe,
        PortalsShipmentTrackingEventsModule,
        PortalsSchedulesSearchWithoutRoutingModule,
        PortalsBookingWizardModule.forRoot({ wrap: true }),
        StoreModule.forFeature('portal', {
            shipments: shipmentsReducer,
        }),
        EffectsModule.forFeature([ShipmentsEffects]),
    ],
    exports: [],
})
export class PortalModule {}
