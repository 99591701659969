import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { createAuth0Client } from '@auth0/auth0-spa-js';
import { Auth0Client } from '@auth0/auth0-spa-js/dist/typings/Auth0Client';
import {
    from,
    of,
    Observable,
    BehaviorSubject,
    combineLatest,
    throwError,
} from 'rxjs';
import {
    tap,
    catchError,
    concatMap,
    shareReplay,
    take,
    map,
    first,
} from 'rxjs/operators';

import { environment } from '@qwyk/hub/environment';
import { AuthenticationFacade } from '../state/authentication.facade';
import * as AuthenticationActions from '../state/authentication.actions';

@Injectable({
    providedIn: 'root',
})
export class AuthenticationService {
    // Create an observable of Auth0 instance of client
    auth0Client$ = (
        from(
            createAuth0Client({
                domain: environment.auth0.domain,
                clientId: environment.auth0.clientId,
                authorizationParams: environment.auth0.authorizationParams,
            })
        ) as Observable<Auth0Client>
    ).pipe(
        shareReplay(1), // Every subscription receives the same shared value
        catchError(err => throwError(err))
    );

    getTokenSilently$ = this.auth0Client$.pipe(
        concatMap((client: Auth0Client) => from(client.getTokenSilently()))
    );
    // Create subject and public observable of access token
    private accessTokenSubject$ = new BehaviorSubject<string | null>(null);
    accessToken$ = this.accessTokenSubject$.asObservable();

    // Define observables for SDK methods that return promises by default
    // For each Auth0 SDK method, first ensure the client instance is ready
    // concatMap: Using the client instance, call SDK method; SDK returns a promise
    // from: Convert that resulting promise into an observable
    isAuthenticated$ = this.auth0Client$.pipe(
        concatMap((client: Auth0Client) => from(client.isAuthenticated()))
    );
    handleRedirectCallback$ = this.auth0Client$.pipe(
        concatMap((client: Auth0Client) =>
            from(client.handleRedirectCallback())
        )
    );
    // Create subject and public observable of user profile data
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private userProfileSubject$ = new BehaviorSubject<any>(null);
    userProfile$ = this.userProfileSubject$.asObservable();
    // Create a local property for login status
    loggedIn: boolean | null = null;

    constructor(
        private http: HttpClient,
        private authentication: AuthenticationFacade
    ) {}

    // getUser$() is a method because options can be passed if desired
    // https://auth0.github.io/auth0-spa-js/classes/auth0client.html#getuser
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    getUser$(): Observable<any> {
        return this.auth0Client$.pipe(
            concatMap((client: Auth0Client) => from(client.getUser()))
        );
    }

    setupLocalAuthentication() {
        return this.isAuthenticated$.pipe(
            concatMap((loggedIn: boolean) => {
                if (loggedIn) {
                    // If authenticated, return stream that emits user object and token
                    return combineLatest([
                        this.getUser$(),
                        this.getTokenSilently$,
                    ]);
                }
                return of(loggedIn);
            })
        );
    }

    localAuthSetup() {
        // This should only be called on app initialization
        // Check if user already has an active session with Auth0
        const checkAuth$ = this.isAuthenticated$.pipe(
            concatMap((loggedIn: boolean) => {
                if (loggedIn) {
                    // If authenticated, return stream that emits user object and token
                    return combineLatest([
                        this.getUser$(),
                        this.getTokenSilently$,
                    ]);
                }
                // If not authenticated, return stream that emits 'false'
                return of(loggedIn);
            })
        );
        const checkAuthSub = checkAuth$.subscribe(
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            (response: any[] | boolean) => {
                // If authenticated, response will be array of user object and token
                // If not authenticated, response will be 'false'
                // Set subjects appropriately
                if (response) {
                    const user = response[0];
                    const token = response[1];
                    this.userProfileSubject$.next(user);
                    this.accessTokenSubject$.next(token);
                }
                this.loggedIn = !!response;
                // Clean up subscription
                checkAuthSub.unsubscribe();
            }
        );
    }

    login(redirectPath = `${window.location.origin}/callback`) {
        // A desired redirect path can be passed to login method
        // (e.g., from a route guard)
        // Ensure Auth0 client instance exists
        this.auth0Client$.subscribe((client: Auth0Client) => {
            // Call method to log in
            client.loginWithRedirect({
                authorizationParams: {
                    redirect_uri: redirectPath,
                },
            });
        });
    }

    handleAuth0Callback() {
        //let targetRoute: string;
        return this.auth0Client$.pipe(
            // Have client, now call method to handle auth callback redirect
            concatMap(() => this.handleRedirectCallback$),
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            tap(cbRes => {
                // Get and set target redirect route from callback results
                /*
                targetRoute =
                    cbRes.appState && cbRes.appState.target
                        ? cbRes.appState.target
                        : '/';
                */
            }),
            first(),
            concatMap(() => {
                // Redirect callback complete; create stream returning
                // user data, token, and authentication status
                return combineLatest([
                    this.getUser$(),
                    this.getTokenSilently$,
                    this.isAuthenticated$,
                ]);
            }),
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            catchError(err => {
                return of(AuthenticationActions.login);
            })
        );
    }

    handleAuthCallback() {
        // Only the callback component should call this method
        // Call when app reloads after user logs in with Auth0
        //let targetRoute: string; // Path to redirect to after login processsed
        // Ensure Auth0 client instance exists
        const authComplete$ = this.auth0Client$.pipe(
            // Have client, now call method to handle auth callback redirect
            concatMap(() => this.handleRedirectCallback$),
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            tap(cbRes => {
                // Get and set target redirect route from callback results
                /*
                targetRoute =
                    cbRes.appState && cbRes.appState.target
                        ? cbRes.appState.target
                        : '/';
                        */
            }),
            concatMap(() => {
                // Redirect callback complete; create stream returning
                // user data, token, and authentication status
                return combineLatest([
                    this.getUser$(),
                    this.getTokenSilently$,
                    this.isAuthenticated$,
                ]);
            })
        );
        // Subscribe to authentication completion observable
        // Response will be an array of user, token, and login status
        authComplete$.subscribe(([user, token, loggedIn]) => {
            console.log('authcomplete');
            // Update subjects and loggedIn property
            this.authentication.handleCallbackSuccess(token);
            this.userProfileSubject$.next(user);
            this.accessTokenSubject$.next(token);
            this.loggedIn = loggedIn;
            // Redirect to target route after callback processing

            // const router = this.injector.get(Router);
            // // router.navigate(['/404']);
            // router.navigate([targetRoute]);
        });
    }

    logout(redirectTo: string) {
        // Ensure Auth0 client instance exists
        this.auth0Client$.pipe(take(1)).subscribe((client: Auth0Client) => {
            // Call method to log out
            client.logout({
                logoutParams: {
                    returnTo: redirectTo,
                },
            });
        });
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    loadUser(): Observable<any> {
        return (
            this.http
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                .get<{ data: any; meta: any }>(
                    `${environment.backendServer}/api/hub/organization/users/me`
                )
                .pipe(
                    take(1),
                    map(res => ({ user: res.data, meta: res.meta }))
                )
        );
    }

    resetImpersonation(): Observable<void> {
        return this.http
            .post<void>(
                `${environment.backendServer}/api/hub/organization/users/me/reset-admin-impersonation`,
                null
            )
            .pipe(take(1));
    }
}
