<ng-container *ngIf="!noRatesReason">
    <h5 class="font-weight-normal">Not finding any rates to your liking?</h5>
    <p class="text-muted">Let us know and we'll send you an updated offer.</p>
    <hr *ngIf="!sent">
</ng-container>
<div class="alert alert-success" *ngIf="sent">
    <div class="font-weight-bold">Thank you, your request was sent!</div>
    <div>Someone will respond to it as soon as possible.</div>
</div>
<form class="form" [formGroup]="form" (submit)="submit()" *ngIf="!sent">
    <div class="form-group">
        <label for="reason">Please let us know why you'd like a custom rate</label>
        <ng-select [items]="reasons" formControlName="reason" id="reason" placeholder="Select a reason"
            [ngClass]="{'is-invalid': form.get('reason').invalid && form.get('reason').touched}"></ng-select>
        <div class="invalid-feedback">Please select a reason.</div>
    </div>
    <div class="form-group">
        <label for="remarks">Any other remarks you'd like to send us?</label>
        <textarea id="remarks" name="remarks" formControlName="remarks" class="form-control" rows="3" maxlength="255"
            placeholder="Remarks"></textarea>
    </div>
    <div class="alert alert-danger" *ngIf="error">Something went wrong sending your request, please try again or contact
        support if the problem persists.</div>
    <div class="form-group">
        <button class="btn btn-primary px-3" [disabled]="form.disabled">
            <ng-container *ngIf="sending">
                <fa-icon [icon]="['far', 'spinner']" animation="spin-pulse" class="mr-2"></fa-icon>
                Sending
            </ng-container>
            <ng-container *ngIf="!sending">
                <fa-icon [icon]="['far', 'paper-plane']" class="mr-2"></fa-icon>
                Send
            </ng-container>
        </button>
        <button class="btn btn-light ml-2" (click)="form.reset()" type="button" [disabled]="form.disabled">
            Reset
        </button>
    </div>
    <div class="text-muted small">
        You'll be notified here, and by email, when a new offer has been posted.
    </div>
</form>
